import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import MaterialButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MaterialButtonGroup from '@mui/material/ButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from '@mui/material';

// TODO deprecate all components from here
export const colors = {
  dark: {
    text: '#fff',
    reverseText: '#000',
    borderPrimary: '#fff',
    borderSecondary: '#333',
    borderLight: '#1b3847',
    borderLighter: '#2a546b',
    primary: '#10242C',
    lightPrimary: '#0c1c23',
    darkPrimary: '#09151a',
    secondary: '#474747',
    accent: '#ffcc1b',
    accentUnderlay: '#dbb018',
    checkBox: '#fff',
  },
  light: {
    text: '#000',
    reverseText: '#fff',
    borderPrimary: '#000',
    borderSecondary: '#eee',
    borderLight: '#ddd',
    borderLighter: '#ddd',
    primary: '#fff',
    lightPrimary: '#cccccc',
    darkPrimary: '#999999',
    secondary: '#ccc',
    accent: '#ffcc1b',
    checkBox: '#ddd',
  },
};

export const useStyles = makeStyles((theme) => ({
  content: {
    height: 30,
    padding: 10,
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-around',
    backgroundColor: '#f4f0e8',
  },
  accentContent: {
    backgroundColor: 'orange',
  },
  tableMarginContainer: {
    margin: 15,
    padding: 5,
  },
  tableContainer: {
    padding: 5,
    margin: 5,
  },
  boldLabel: {
    marginBottom: 0,
    fontWeight: 700,
    fontSize: '1.2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em',
    },
  },
  textInput: {
    fontSize: 14,
  },
  homeTextInput: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 0,
  },
  rowTitle: {
    marginLeft: 5,
    marginRight: 5,
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%',
  },
  rowText: {
    marginLeft: 5,
    marginRight: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowContainer: {
    marginLeft: 5,
    marginRight: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputContainer: {
    margin: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputFillContainer: {
    margin: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  label: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
  },

  textField: {
    fontSize: 14,
    width: '100%',
  },

  button: {
    background: '#90caf9',
    width: '12em',
    fontSize: '0.9em',
    color: '#000',
    borderStyle: 'none',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6em',
    },
  },

  cell: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },

  warning: {
    fontSize: '0.9em',
    color: 'red',
  },
}));

const outlinedInputStyles = {
  root: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 14,
    },
  },
};

const accentInputStyle = {
  root: {
    '& label.Mui-focused': {
      color: '#071b24',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#071b24',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.dark.text,
      },
      '&:hover fieldset': {
        borderColor: '#071b24',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#071b24',
      },
    },
  },
};

export const CssFormControl = withStyles(accentInputStyle)(FormControl);
const CssTextField = withStyles(outlinedInputStyles)(TextField);

export const MaterialCheckbox = withStyles({
  root: {
    color: '#fff',
    '&$checked': {
      color: '#fff',
    },
  },
  checked: {},
})(Checkbox);

export const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#ffedad',
  },
  barColorPrimary: {
    backgroundColor: '#ffcc1b',
  },
})(LinearProgress);

export function Button(props) {
  const { color, ...other } = props;
  const classes = useStyles(props);
  return (
    <MaterialButton
      variant="outlined"
      classes={{ outlined: classes.button }}
      className={classes.button}
      {...other}
    />
  );
}

export function ButtonGroup(props) {
  const { color, ...other } = props;
  //const classes = useStyles(props);
  return (
    <MaterialButtonGroup
      style={{ backgroundColor: '#ffcc1b' }}
      size="medium"
      fullWidth
      {...other}
    />
  );
}

export function TextInput(props) {
  const { color, className, labelClass, ...other } = props;
  const classes = useStyles(props);
  return (
    <CssTextField
      //required={true}
      hiddenLabel={true}
      variant="outlined"
      className={className ? className : classes.textField}
      inputprops={{
        classes: {
          input: labelClass ? labelClass : classes.label,
        },
      }}
      {...other}
    />
  );
}

export function RowTitle(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.rowTitle}>
      <p style={{ margin: 0, padding: 0 }}>{props.title}</p>
    </div>
  );
}

export function RowText(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.rowText}>
      <p style={{ margin: 0, padding: 0, ...props.textStyle }}>{props.title}</p>
    </div>
  );
}

export function RowTextInput(props) {
  const { value, input, label, className, labelClass, ...other } = props;
  const classes = useStyles(props);
  return (
    <div className={input.fill ? classes.inputFillContainer : classes.inputContainer}>
      <TextInput
        label={input && input.label ? input.label : ''}
        error={input && input.error ? true : false}
        value={value ?? (input && input.value ? input.value : '')}
        hiddenLabel={input && input.error ? false : true}
        labelClass={labelClass}
        className={className}
        {...other}
      />
    </div>
  );
}

export function HomeTextField(props) {
  const { input, ...other } = props;
  const classes = useStyles(props);
  return (
    <TextInput
      className={classes.homeTextInput}
      variant={'outlined'}
      label={input && input.label ? input.label : ''}
      error={input && input.error ? true : false}
      value={input && input.value ? input.value : ''}
      {...other}
    />
  );
}

export function FormTextField(props) {
  const { input = {}, label, items, labelClass, ...other } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.centeredRowContainer}>
      <CssFormControl className={classes.textField} style={{ marginTop: 16, marginBottom: 8 }}>
        <TextInput
          //className={classes.homeTextInput}
          variant={'standard'}
          label={input && input.label ? input.label : ''}
          error={input && input.error ? true : false}
          value={input && input.value ? input.value : ''}
          {...other}
        />
        {input.error ? (
          <FormHelperText className={'MuiFormHelperText-root Mui-error'}>
            {input.error}
          </FormHelperText>
        ) : null}
      </CssFormControl>
    </div>
  );
}

export function RowSelect(props) {
  const { input = {}, label, value, items, labelClass, ...other } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.centeredRowContainer}>
      <CssFormControl className={classes.textField} style={{ marginTop: 16, marginBottom: 8 }}>
        <InputLabel id={'select-label-' + input.label} className={input.error ? 'Mui-error' : ''}>
          {label ?? input.label}
        </InputLabel>
        <Select
          displayEmpty
          labelId={'select-label-' + input.label}
          id={'select-' + input.label}
          value={value ? value : input.value ? input.value : ''}
          label={label ?? input.label}
          {...other}
        >
          {items}
        </Select>
        {input.error ? (
          <FormHelperText className={'MuiFormHelperText-root Mui-error'}>
            {input.error}
          </FormHelperText>
        ) : null}
      </CssFormControl>
    </div>
  );
}

export function MultiSelect(props) {
  const { input = {}, label, value, items, labelClass, ...other } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.centeredRowContainer}>
      <CssFormControl className={classes.textField} style={{ marginTop: 16, marginBottom: 8 }}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={items}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          value={value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          {...other}
          renderInput={(params) => <TextField {...params} label={label ?? input.label} />}
          onChange={(e, value) => props.onChange(value.map((item) => item.value))}
        />
        {input.error ? (
          <FormHelperText className={'MuiFormHelperText-root Mui-error'}>
            {input.error}
          </FormHelperText>
        ) : null}
      </CssFormControl>
    </div>
  );
}

export function RowDatePicker(props) {
  const { input, label, className, labelClass, format, onChange, ...other } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.centeredRowContainer}>
      <CssFormControl className={classes.textField} style={{ marginTop: 16, marginBottom: 8 }}>
        <DatePicker
          // disableToolbar
          // autoOk
          className={classes.textField}
          variant="inline"
          label={label ? label : input.label}
          //clearable={true}
          value={input && input.value ? input.value : null}
          //minDate={new Date()}
          margin="normal"
          onChange={(date) => onChange(date)}
          format={format || 'DD/MM/YYYY'}
          renderInput={(params) => <TextField {...params} />}
          {...other}
        />
        {input.error ? (
          <FormHelperText className={'MuiFormHelperText-root Mui-error'}>
            {input.error}
          </FormHelperText>
        ) : null}
      </CssFormControl>
    </div>
  );
}

export function RowCheckbox(props) {
  const { input = {}, label, value, labelClass, ...other } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.centeredRowContainer}>
      <FormControlLabel
        style={{ marginTop: 16, marginBottom: 8 }}
        id={'checkbox-label-' + input.label}
        label={label ?? input.label}
        labelPlacement="start"
        control={
          <Checkbox
            id={'checkbox-' + input.label}
            checked={value ? value : input.value ? input.value : false}
            {...other}
          />
        }
      />
    </div>
  );
}

export function StatusText(props) {
  const { text } = props;
  const classes = useStyles(props);
  return text ? (
    <div className={classes.inputContainer}>
      <p className={classes.boldLabel}>{text}</p>
    </div>
  ) : null;
}
