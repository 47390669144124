import React, { useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { MdRefresh, MdSort } from 'react-icons/md';

import { request } from '../helpers/api';
import { onTextChange } from '../helpers/functions';

import FormComponent from '../components/FormComponent';
import { useTranslation } from 'react-i18next';
import VirtualizedList from '../components/VirtualizedList';

import ConfirmDialog from '../components/ConfirmDialog';

import { connect } from 'react-redux';
import { SET_COMMON_PROP } from '../actions/CommonActions';

import { toast } from 'react-toastify';

import AutoSizer from 'react-virtualized-auto-sizer';
import { Chip, Menu, MenuItem } from '@mui/material';

function CompanyRegister(props) {
  const { t } = useTranslation();

  const initialInputs = {
    name: {
      type: 'textField',
      label: t('companyName'),
      value: '',
      error: '',
      optional: false,
    },
    companyId: {
      type: 'textField',
      label: t('companyId'),
      value: '',
      error: '',
      optional: false,
    },
    vatId: {
      type: 'textField',
      label: t('vatId'),
      value: '',
      error: '',
      optional: true,
    },
    licenceGroups: [
      {
        softwareCompanyId: '',
        ivLicenceCount: 0,
        erLicenceCount: 0,
        lvLicenceCount: 0,
      },
    ],
  };

  const editInputs = {
    id: {
      label: 'Id',
      type: 'textField',
      value: '',
      error: '',
      readOnly: true,
    },
    ...initialInputs,
  };

  const [inputs, setInputs] = useState(initialInputs);
  const [loading, setLoading] = useState('');
  const [editing, setEditing] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });
  const [sort, setSort] = useState({ by: 'none', order: 'asc' });
  const [sortEl, setSortEl] = useState(null);
  const [licenceGroupsError, setLicenceGroupsError] = useState('');

  const setDialogVisible = (visible) => {
    setDialog({
      ...dialog,
      visible,
    });
  };

  const handleClear = () => setInputs(initialInputs);

  const handleFinally = (clear = true) => {
    setLoading('');
    if (clear) {
      handleClear();
      if (editing) setEditing(false);
    }
  };

  const handleChange = (name, type, event, numeric) => {
    let _value;

    if (type === 'datePicker') {
      _value = event;
    } else if (type === 'multiselect') {
      _value = event;
    } else {
      _value = onTextChange(event.target.value, numeric);
    }

    setInputs({
      ...inputs,
      [name]: {
        ...inputs[name],
        value: _value,
        error: '',
      },
    });
  };

  const validate = () => {
    let err = false;
    let _inputs = { ...inputs };

    Object.entries(inputs).forEach(([key, val]) => {
      if (key === "licenceGroups") {
        if (val.length === 0) {
          err = true;
          setLicenceGroupsError(t("atLeastOneLicenceGroup"));
        } else {
          setLicenceGroupsError('');
          val.forEach((group, index) => {
            if (!group.softwareCompanyId) {
              err = true;
              _inputs.licenceGroups[index] = {
                ...group,
                error: t("requiredErr"),
              };
            }
          });
        }
      } else if (!val.optional && !val.value) {
        err = true;
        _inputs[key] = {
          ...val,
          error: t("requiredErr"),
        };
      }
    });
  
    if (err) {
      setInputs(_inputs);
    }
  
    return err;
  };  

  const parseInputs = () => ({
    ...Object.entries(inputs)
      .filter(([key]) => key !== "licenceGroups")
      .reduce((prev, [key, val]) => ({ ...prev, [key]: val.value }), {}),
    licenceGroups: inputs.licenceGroups,
  });  

  const parseClient = (client) => {
    const parsedClient = Object.entries(client).reduce(
      (prev, [key, value]) => {
        if (key === "licenceGroups") {
          prev.licenceGroups = value.map((group) => ({
            softwareCompanyId: group.softwareCompanyId || "",
            ivLicenceCount: group.ivLicenceCount || 0,
            erLicenceCount: group.erLicenceCount || 0,
            lvLicenceCount: group.lvLicenceCount || 0,
          }));
        } else if (prev[key]) {
          prev[key].value = value;
        }
        return prev;
      },
      { ...editInputs }
    );
    return parsedClient;
  };
  

  const handleEdit = (client) => {
    setEditing(true);
    setInputs(parseClient(client));
  };

  const handleGet = async () => {
    const doSort = (a, b) => {
      if (sort.by === 'name') {
        if (sort.order === 'asc') return a.name.localeCompare(b.name);
        else return b.name.localeCompare(a.name);
      }
      return 0;
    };
    await request('clients')
      .then((res) => {
        if (res.status === 200) {
          let data = sort.by === 'none' ? res.data : res.data.sort(doSort);
          props.SET_COMMON_PROP({ data: data, prop: 'clients' });
          if (loading.includes('_')) {
            handleEdit(res.data.find((x) => x.id === loading.split('_')[1]));
          }
        } else if (res.status === 204) {
          props.SET_COMMON_PROP({ data: [], prop: 'clients' });
        }
      })
      .catch(() => null);

    await request('softwareCompanies')
      .then((res) => {
        if (res.status === 200) {
          props.SET_COMMON_PROP({ data: res.data, prop: 'softwareCompanies' });
          if (loading.includes('_')) {
            handleEdit(res.data.find((x) => x.id === loading.split('_')[1]));
          }
        } else if (res.status === 204) {
          props.SET_COMMON_PROP({ data: [], prop: 'softwareCompanies' });
        }
      })
      .catch(() => null);

    setLoading('');
  };

  const handleDelete = (_id) => {
    request('clients/' + _id, 'delete')
      .then((res) => {
        if (res.status === 204) {
          props.SET_COMMON_PROP({
            prop: 'clients',
            type: 'removeFromArr',
            value: { id: _id },
            idProp: 'id',
          });
        }
      })
      .finally(handleFinally);
  };

  const handleEditPut = (_id) => {
    if (!validate()) {
      request('clients/' + _id, 'put', parseInputs())
      .then((res) => {
        if (res.status === 200) {
          if (loading.includes('_')) {
            handleEdit(res.data);
          }
          props.SET_COMMON_PROP({
            prop: 'clients',
            type: 'replaceArrObj',
            value: res.data,
            idProp: 'id',
          });
          handleFinally(false);
          toast.success(t('saveSuccessful'));
        } else {
          handleFinally(false);
        }
      })
      .finally(() => handleFinally(false));
    } else {setLoading('')}
  };

  const handleAdd = () => {
    if (!validate()) {
      request('clients', 'post', parseInputs())
        .then((res) => {
          if (res.status === 200) {
            props.SET_COMMON_PROP({
              prop: 'clients',
              type: 'addToArr',
              value: res.data,
              idProp: 'id',
            });
            handleFinally(true);
          } else {
            handleFinally(false);
          }
        })
        .catch((err) => {
          handleFinally(false);
        });
    } else {
      setLoading('');
    }
  };

  const handleSetSort = (by, order) => {
    setSort({ by, order });
    setSortEl(null);
    setLoading('get');
  };

  // LicenceGroup handling
  const handleAddLicenceGroup = () => {
    setLicenceGroupsError('');
    setInputs((prevInputs) => ({
      ...prevInputs,
      licenceGroups: [
        {
          softwareCompanyId: "",
          ivLicenceCount: 0,
          erLicenceCount: 0,
          lvLicenceCount: 0,
        },
        ...prevInputs.licenceGroups,
      ],
    }));
  };
  
  const handleRemoveLicenceGroup = (index) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      licenceGroups: prevInputs.licenceGroups.filter((_, i) => i !== index),
    }));
  };
  
  const handleLicenceGroupChange = (index, field, value) => {
    setInputs((prevInputs) => {
      const updatedGroups = [...prevInputs.licenceGroups];
      updatedGroups[index][field] = field.endsWith('Count')
        ? parseInt(value, 10) || 0
        : value;
  
      if (field === "softwareCompanyId" && value) {
        updatedGroups[index].error = "";
      }
  
      return { ...prevInputs, licenceGroups: updatedGroups };
    });
  };   

  const calculateTotals = (licenceGroups) => {
    return licenceGroups.reduce(
      (totals, group) => ({
        iv: totals.iv + (group.ivLicenceCount || 0),
        er: totals.er + (group.erLicenceCount || 0),
        lv: totals.lv + (group.lvLicenceCount || 0),
      }),
      { iv: 0, er: 0, lv: 0 }
    );
  };

  useEffect(() => {
    if (loading.startsWith('get')) handleGet();
    else if (loading.startsWith('delete')) handleDelete(loading.split('_')[1]);
    else if (loading === 'add') handleAdd();
    else if (loading.startsWith('edit')) handleEditPut(loading.split('_')[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          width: props.width,
          height: props.height,
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <Stack sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" p={1} alignItems="center">
              <Typography variant="h6">{t('companies')}</Typography>

              <Box>
                <IconButton disabled={loading ? true : false} onClick={() => setLoading('get')}>
                  <MdRefresh />
                </IconButton>
                <IconButton
                  disabled={loading ? true : false}
                  onClick={(e) => setSortEl(e.currentTarget)}
                >
                  <MdSort />
                </IconButton>
                <Menu anchorEl={sortEl} open={Boolean(sortEl)} onClose={() => setSortEl(null)}>
                  <MenuItem onClick={() => handleSetSort('none', 'asc')}>
                    {t('sortByNone')}
                  </MenuItem>
                  <MenuItem onClick={() => handleSetSort('name', 'asc')}>
                    {t('sortByNameAsc')}
                  </MenuItem>
                  <MenuItem onClick={() => handleSetSort('name', 'desc')}>
                    {t('sortByNameDesc')}
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>

            <Divider />

            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualizedList
                  data={props.clients}
                  emptyDataString={t('noClients')}
                  height={props.height - 57}
                  width={width - 1}
                  getName={(data) => {
                    const { iv, er, lv } = calculateTotals(data.licenceGroups || []);
                    return `${data.companyId} ${data.name ? data.name + ' ' : ''} - IV: ${iv}, ER: ${er}, LV: ${lv}`;
                  }}
                  onItemClick={handleEdit}
                />
              )}
            </AutoSizer>
          </Stack>
        </div>

        <Divider orientation="vertical" />

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: 8,
          }}
        >
          <h4>{t('clientInfo')}</h4>
          {Object.keys(inputs)
            .filter((inputKey) => inputKey !== 'licenceGroups')
            .map((inputKey) => {
              const _key = `Input${inputKey}`;
              return (
                <div
                  key={_key}
                  style={{
                    width: '100%',
                  }}
                >
                  <FormComponent
                    input={inputs[inputKey]}
                    prop={inputKey}
                    handleChange={handleChange}
                    parentProps={props}
                  />
                </div>
              );
            })}

          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: '1rem 0 2rem',
              gap: '0.5rem',
            }}
          >
            <h4>{t('licencesTitle')}</h4>
            <Stack direction="row" gap={2} marginBottom={2}>
              {(() => {
                const { iv, er, lv } = calculateTotals(inputs.licenceGroups);
                return (
                  <>
                    <Chip label={`IV Total: ${iv}`} />
                    <Chip label={`ER Total: ${er}`} />
                    <Chip label={`LV Total: ${lv}`} />
                  </>
                );
              })()}
            </Stack>

            <Button
              variant="outlined"
              disabled={loading ? true : false}
              onClick={handleAddLicenceGroup}
            >
              {t('addLicenceGroup')}
            </Button>

            {licenceGroupsError && (
              <Typography
                variant="caption"
                color="error"
                sx={{ marginTop: '0.5rem', display: 'block' }}
              >
                {licenceGroupsError}
              </Typography>
            )}

            {inputs.licenceGroups.map((group, index) => (
              <div
                key={`licenceGroup-${index}`}
                style={{
                  marginBottom: '5px',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  padding: '1rem',
                }}
              >
                <FormComponent
                  input={{
                    type: 'picker',
                    label: t('softwareCompany'),
                    value: group.softwareCompanyId,
                    optionsProp: 'softwareCompanies',
                    optionsValueProp: 'id',
                    optionsNameProp: 'name',
                    optional: false,
                    error: group.error || '',
                  }}
                  prop={`softwareCompanyId-${index}`}
                  handleChange={(name, type, event) =>
                    handleLicenceGroupChange(index, 'softwareCompanyId', event.target.value)
                  }
                  parentProps={props}
                />
                <Stack direction="row" justifyContent="space-between">
                  {['ivLicenceCount', 'erLicenceCount', 'lvLicenceCount'].map((licenceType) => (
                    <FormComponent
                      key={`${licenceType}-${index}`}
                      input={{
                        type: 'textField',
                        label: t(licenceType),
                        value: group[licenceType],
                        error: '',
                        numeric: true,
                      }}
                      prop={`${licenceType}-${index}`}
                      handleChange={(name, type, event) =>
                        handleLicenceGroupChange(index, licenceType, event.target.value)
                      }
                      parentProps={props}
                    />
                  ))}
                </Stack>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  style={{ marginTop: '0.5rem' }}
                  onClick={() => handleRemoveLicenceGroup(index)}
                >
                  {t('removeLicenceGroup')}
                </Button>
              </div>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingBottom: '2rem',
            }}
          >
            {editing ? (
              <>
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={handleFinally}
                  >
                    {t('close')}
                  </Button>
                </div>
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={() =>
                      setDialog({
                        title: t('deleteClient') + '?',
                        text: t('deleteConfirmation') + ' ' + t('deleteClientConfirmation'),
                        visible: true,
                        value: inputs.name.value,
                        fn: () => setLoading('delete_' + inputs.id.value),
                      })
                    }
                  >
                    {t('delete')}
                  </Button>
                </div>
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={() => setLoading('edit_' + inputs.id.value)}
                  >
                    {t('save')}
                  </Button>
                </div>
              </>
            ) : (
              <Button
                variant="contained"
                disabled={loading ? true : false}
                onClick={() => setLoading('add')}
              >
                {t('add')}
              </Button>
            )}
          </div>
        </div>
      </Box>
      <ConfirmDialog
        title={dialog.title}
        open={dialog.visible}
        setOpen={setDialogVisible}
        value={dialog.value}
        onConfirm={dialog.fn}
      >
        {dialog.text + ' ' + (dialog.value || '')}
      </ConfirmDialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    clients: state.common.clients,
    softwareCompanies: state.common.softwareCompanies,
  };
};

export default connect(mapStateToProps, {
  SET_COMMON_PROP,
})(CompanyRegister);
