import React, { useRef, useEffect, useState } from "react";
import update from "immutability-helper";
import moment from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { MdContentCopy, MdEdit, MdRefresh } from "react-icons/md";
import { TbJson } from "react-icons/tb";

import {
  setDraftVersion,
  requestWholeSalers,
  getVersions,
} from "../helpers/requests";

import { request, fileUpload } from "../helpers/api";
import { onTextChange } from "../helpers/functions";

import Form from "../components/Form";
import Tooltip from "../components/Tooltip";
import FormComponent from "../components/FormComponent";
import StyledDropzone from "../components/StyledDropzone";
import ProgressIndicator from "../components/ProgressIndicator";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";

import LoadingButton from "@mui/lab/LoadingButton";

import ConfirmDialog from "../components/ConfirmDialog";
import Button from "@mui/material/Button";

import { connect } from "react-redux";
import {
  SET_VERSION,
  SET_REGISTRY_VERSIONS,
  SET_REGISTRY_VERSION,
  SET_PUBLISHED_VERSION,
} from "../actions/VersionsActions";
import { SET_COMMON_PROP } from "../actions/CommonActions";

import { toast } from "react-toastify";

const canEdit = true; //process.env.NODE_ENV === "development";

const initialRequesting = { loading: null, params: null };
function VersionInfoCard({
  t,
  title,
  registryVersionId,
  registryVersionObject,
  setDialog,
  SET_VERSION,
  publishedVersion,
  selectedVersion,
  versionAsSelect,
  versions,
  handlePublish,
  creatingDraftOrCopy,
  creatingJSON,
  creatingTestJSON,
  disableButtons,
  isDraft,
  setVersionCloneProgress,
  setJSONProgress,
  setTestJSONProgress,
  _versionCloneProgress,
  _JSONProgress,
  _testJSONProgress,
  handleDelete,
  setFormDialog,
  hasDraft,
  SET_REGISTRY_VERSION,
}) {
  const [requesting, setRequesting] = useState(initialRequesting);
  const [cardMenu, setCardMenu] = useState({ open: false });

  const handleCardMenuOpen = (items) => (event) => {
    setCardMenu({
      open: true,
      anchorEl: event.currentTarget,
      items: items,
    });
  };
  const handleCardMenuClose = () => {
    setCardMenu({
      open: false,
    });
  };

  const requestCreateJSON = async (registryVersionId, createExampleData) => {
    const toastPrefix = createExampleData ? "test" : "";
    request("packages/versions/json", "post", {
      example: createExampleData,
      version: registryVersionId,
    }).then((res) => {
      // 202 when server starts processing json creation
      if (res.status === 202) {
        toast.info(
          t(toastPrefix + "JSONCreationStarted") +
            ", " +
            t("canTakeMultipleMinutes")
        );
        if (createExampleData) {
          const progressData = {
            processName: "JSONCreationExample",
            progress: "0",
            registryVersion: registryVersionId,
            step: 0,
          };
          _testJSONProgress.current = [
            ..._testJSONProgress.current,
            progressData,
          ];
          setTestJSONProgress((_x) => [..._x, progressData]);
        } else {
          const progressData = {
            processName: "JSONCreation",
            progress: "0",
            registryVersion: registryVersionId,
            step: 0,
          };
          _JSONProgress.current = [..._JSONProgress.current, progressData];
          setJSONProgress((_x) => [..._x, progressData]);
        }
        return false;
      } else {
        return false;
      }
    });
  };

  const downloadJSON = (registryVersionId, getExampleData, getLimitedData) => {
    // const link = document.createElement("a");
    axios({
      method: "get",
      url: `${
        axios.defaults.baseURL +
        (axios.defaults.baseURL.endsWith("/") ? "" : "/")
      }packages/versions/json?example=${!!getExampleData}&limitedData=${!!getLimitedData}&version=${registryVersionId}`,
      headers: {
        "content-type": "application/json",
      },
      params: {
        registryVersion: localStorage.getItem("registryVersion"),
      },
      responseType: "blob",
    })
      .then((res) => [new Blob([res.data]), res.headers["content-disposition"]])
      .then(([blob, disposition]) => {
        const filename = disposition
          .split(";")
          .find((x) => x.includes("filename"))
          .split("=")[1];
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.type = "application/json";
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.download = filename;
        link.click();
        link.remove();
      });
    // link.type = "application/json";
    // link.target = "_blank";
    // link.rel = "noopener noreferrer";
    // link.click();
    // link.remove();
  };

  const _setDraftVersion = async (registryVersionId) => {
    const draftCreated = await setDraftVersion(registryVersionId, t);
    setRequesting(initialRequesting);

    if (draftCreated) {
      SET_VERSION({
        registryVersion: registryVersionId,
        editing: !!registryVersionId,
      });
    } else {
      const progressData = {
        processName: "VersionClone",
        progress: "0",
        registryVersion: registryVersionId,
        step: 0,
      };
      _versionCloneProgress.current = [
        ..._versionCloneProgress.current,
        progressData,
      ];
      setVersionCloneProgress((_x) => [..._x, progressData]);
    }
  };

  const handleFormSave = (values) => {
    request("packages/versions/config", "put", {
      // remove dot notations e.g. "nodeText.comment" => "comment"
      // also remove nodeText and added since form returns all the original values
      ...Object.entries(values).reduce((acc, [key, value]) => {
        const splitKey = key.split(".");
        if (splitKey.length > 1) acc[splitKey.pop()] = value;
        else acc[key] = value;
        return acc;
      }, {}),
      nodeText: null,
      added: null,
      registryVersion: registryVersionId,
      comment: values.nodeText.comment,
    })
      .then((res) => {
        // 202 when server starts processing json creation
        if (res.status === 200) {
          toast.success(t("saveSuccessful"));
          SET_REGISTRY_VERSION({
            data: res.data,
            registryVersion: registryVersionId,
          });
          setFormDialog((cur) => ({ ...cur, open: false, loading: false }));
        } else {
          setFormDialog((cur) => ({ ...cur, loading: false }));
        }
      })
      .catch(() => {
        setFormDialog((cur) => ({ ...cur, loading: false }));
      });
  };

  const handleVersionCopyToNewVersion = () => {
    request("packages/versions/copyToNew", "post", {
      version: registryVersionId,
    }).then((res) => {
      // 202 when server starts processing json creation
      if (res.status === 202) {
        const progressData = {
          processName: "VersionClone",
          progress: "0",
          registryVersion: registryVersionId,
          step: 0,
        };
        _versionCloneProgress.current = [
          ..._versionCloneProgress.current,
          progressData,
        ];
        setVersionCloneProgress((_x) => [..._x, progressData]);
        toast.info(
          t("versionCopyStarted") + ", " + t("canTakeMultipleMinutes")
        );
      }
    });
  };

  useEffect(() => {
    if (requesting.loading === "draft") {
      _setDraftVersion(requesting.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting]);

  return (
    <Card
      key={"VersionCard" + registryVersionId}
      sx={{
        minWidth: 275,
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <CardContent
        sx={{
          backgroundColor: versionAsSelect ? "orange" : "#b3e5ff",
          height: "100%",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography gutterBottom variant="h6">
              {title}
            </Typography>
          </Grid>
          {versionAsSelect && Array.isArray(versions) ? (
            <Grid item xs={disableButtons ? 8 : 7}>
              <Select
                value={registryVersionId || ""}
                onChange={(ev) => {
                  setDialog({
                    title: t("publishVersion"),
                    text: t("publishVersionConfirmation"),
                    visible: true,
                    value: ev.target.value,
                    fn: handlePublish,
                  });
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""} disabled>
                  {t("noVersionToDistribute")}
                </MenuItem>
                {versions.map((x, i) => (
                  <MenuItem key={"VersionMenuItem" + i} value={x}>
                    {t("version") + " " + x}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          ) : (
            <Grid item xs={disableButtons ? 8 : 7}>
              <Typography gutterBottom variant="h6">
                {registryVersionId}
              </Typography>
            </Grid>
          )}

          {disableButtons ? null : (
            <Grid item xs={1}>
              <Tooltip hint={t("versionEditTooltip")}>
                <IconButton
                  onClick={() => {
                    setFormDialog({
                      open: true,
                      values: registryVersionObject,
                      form: {
                        data: [
                          {
                            title: registryVersionId + " " + t("versionConfig"),
                            rows: [
                              [
                                {
                                  key: "nodeText.comment",
                                  label: "comment",
                                  type: "textField",
                                  error: "",
                                  validation: "",
                                  mask: "",
                                  fill: true,
                                },
                              ],
                              [
                                {
                                  key: "hourlyRate",
                                  label: "hourlyRate",
                                  type: "textField",
                                  error: "",
                                  validation: "",
                                  mask: "",
                                  fill: true,
                                  numeric: true,
                                },
                              ],
                            ],
                          },
                        ],
                      },
                      handleSubmit: (values) => {
                        setFormDialog((cur) => ({ ...cur, loading: true }));
                        handleFormSave(values);
                      },
                    });
                  }}
                >
                  <MdEdit color="inherit" size={24} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              {t("comment") + ":"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" component="p">
              {registryVersionObject?.nodeText?.comment || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              {t("added") + ":"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" component="p">
              {registryVersionObject?.added
                ? moment(registryVersionObject?.added).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"}
            </Typography>
          </Grid>

          {isDraft ? null : (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" component="p">
                  {t("distributionBegan") + ": "}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" component="p">
                  {registryVersionObject?.publishedAt
                    ? moment(registryVersionObject?.publishedAt).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    : "-"}
                </Typography>
              </Grid>
            </>
          )}

          {isDraft ? null : (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" component="p">
                  {t("distributionEnded") + ": "}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" component="p">
                  {registryVersionObject?.publishedUntil
                    ? moment(registryVersionObject?.publishedUntil).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    : "-"}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              {t("distrubutionJSONCreated") + ": "}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" component="p">
              {registryVersionObject?.jsonCreated
                ? moment(registryVersionObject?.jsonCreated).format(
                    "DD.MM.YYYY HH:mm:ss"
                  )
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {disableButtons ? null : (
        <CardActions
          sx={{ backgroundColor: versionAsSelect ? "orange" : "#b3e5ff" }}
        >
          <Grid container spacing={1} align="center">
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                color="primary"
                loading={creatingJSON}
                loadingPosition="start"
                startIcon={<TbJson />}
                disabled={(isDraft && !hasDraft) || creatingJSON}
                onClick={() =>
                  setDialog({
                    title: t("createDistributionJSONDialogTitle"),
                    text: t("createDistributionJSONDialogInfo"),
                    visible: true,
                    value: registryVersionId,
                    dontDisplayValue: true,
                    fn: requestCreateJSON,
                  })
                }
                variant="contained"
              >
                {t("createDistributionJSON")}
              </LoadingButton>
            </Grid>

            <Grid item xs={6}>
              <Tooltip hint={creatingJSON ? t("JSONCreationInProgress") : null}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  startIcon={<TbJson />}
                  disabled={(isDraft && !hasDraft) || creatingJSON}
                  onClick={handleCardMenuOpen([
                    {
                      title: t("distributionJSON"),
                      onClick: () => {
                        downloadJSON(registryVersionId, false);
                        handleCardMenuClose();
                      },
                    },
                    {
                      title: t("limitedDistributionJSON"),
                      onClick: () => {
                        downloadJSON(registryVersionId, false, true);
                        handleCardMenuClose();
                      },
                    },
                  ])}
                >
                  {t("downloadDistributionJSON")}
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip tip={t("createSampleDataFromDistributionJSONTooltip")}>
                <LoadingButton
                  fullWidth
                  color="primary"
                  loading={creatingTestJSON}
                  loadingPosition="start"
                  startIcon={<TbJson />}
                  disabled={(isDraft && !hasDraft) || creatingTestJSON}
                  onClick={() => requestCreateJSON(registryVersionId, true)}
                  variant="contained"
                >
                  {t("createTestDistributionJSON")}
                </LoadingButton>
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip
                hint={creatingTestJSON ? t("testJSONCreationInProgress") : null}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  startIcon={<TbJson />}
                  disabled={(isDraft && !hasDraft) || creatingTestJSON}
                  onClick={handleCardMenuOpen([
                    {
                      title: t("testDistributionJSON"),
                      onClick: () => {
                        downloadJSON(registryVersionId, true);
                        handleCardMenuClose();
                      },
                    },
                    {
                      title: t("limitedTestDistributionJSON"),
                      onClick: () => {
                        downloadJSON(registryVersionId, true, true);
                        handleCardMenuClose();
                      },
                    },
                  ])}
                >
                  {t("downloadTestDistributionJSON")}
                </Button>
              </Tooltip>
            </Grid>

            {isDraft ? (
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={creatingDraftOrCopy}
                  loadingPosition="start"
                  startIcon={<MdEdit />}
                  disabled={
                    !canEdit ||
                    creatingDraftOrCopy ||
                    selectedVersion === registryVersionId
                  }
                  onClick={() =>
                    setRequesting({
                      loading: "draft",
                      params: registryVersionId,
                    })
                  }
                  variant="contained"
                  color="basic"
                >
                  {creatingDraftOrCopy
                    ? t("creatingDraftOrCopy")
                    : !hasDraft
                    ? t("createDraft")
                    : t("selectVersionForEditing")}
                </LoadingButton>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="basic"
                  variant="contained"
                  disabled={selectedVersion === registryVersionId}
                  onClick={() =>
                    SET_VERSION({
                      registryVersion: registryVersionId,
                      editing: null,
                    })
                  }
                >
                  {t("selectVersionForViewing")}
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                color="basic"
                variant="contained"
                startIcon={<MdContentCopy />}
                disabled={(isDraft && !hasDraft) || creatingDraftOrCopy}
                loading={creatingDraftOrCopy}
                loadingPosition="start"
                onClick={handleVersionCopyToNewVersion}
              >
                {creatingDraftOrCopy
                  ? t("creatingDraftOrCopy")
                  : t("versionCopyToNewVersion")}
              </LoadingButton>
            </Grid>

            {isDraft ? (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  disabled={
                    !canEdit ||
                    !hasDraft ||
                    publishedVersion === registryVersionId
                  }
                  onClick={() =>
                    setDialog({
                      title: t("deleteVersion") + "?",
                      text:
                        t("deleteConfirmation") +
                        " " +
                        t("deleteVersionConfirmation"),
                      dontDisplayValue: false,
                      visible: true,
                      value: registryVersionId,
                      fn: handleDelete,
                    })
                  }
                >
                  {t("deleteDraftVersion")}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  disabled={publishedVersion === registryVersionId}
                  onClick={() =>
                    setDialog({
                      title: t("deleteVersion") + "?",
                      text:
                        t("deleteConfirmation") +
                        " " +
                        t("deleteVersionConfirmation"),
                      dontDisplayValue: false,
                      visible: true,
                      value: registryVersionId,
                      fn: handleDelete,
                    })
                  }
                >
                  {t("deleteVersion")}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      )}

      <Menu
        id="basic-menu"
        anchorEl={cardMenu.anchorEl}
        open={cardMenu.open}
        onClose={handleCardMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {cardMenu.items?.map((x) => (
          <MenuItem key={x.title} onClick={x.onClick}>
            {x.title}
          </MenuItem>
        ))}
      </Menu>
    </Card>
  );
}

function VersionList(props) {
  const { t } = useTranslation();

  const initialInputs = {
    supplier: {
      type: "picker",
      label: t("wholeSaler"),
      value: "",
      error: "",
      optionsProp: "wholeSalers",
      optionsValueProp: "id",
      optionsNameProp: "name",
    },
    language: {
      type: "picker",
      label: t("language"),
      value: "FIN",
      error: "",
      options: ["FIN"],
    },
    validFrom: {
      format: "DD.MM.YYYY",
      type: "datePicker",
      label: t("validFrom"),
      value: "",
      error: "",
    },
    validTo: {
      format: "DD.MM.YYYY",
      type: "datePicker",
      label: t("validTo"),
      value: "",
      error: "",
    },
    productField: {
      type: "picker",
      label: t("productField"),
      value: "",
      error: "",
      optional: true,
      options: ["I", "L"],
    },
  };

  const initialVersionInputs = {
    comment: {
      type: "textField",
      label: t("comment"),
      value: "",
      error: "",
    },
  };

  const [inputs, setInputs] = useState(initialInputs);
  const [uploadScreen, setUploadScreen] = useState();
  const [loading, setLoading] = useState("");
  const [refreshing, setRefreshing] = useState();
  const [formDialog, setFormDialog] = useState({ open: false });

  const [uploadProgress, setUploadProgress] = useState([]);
  const [versionCloneProgress, setVersionCloneProgress] = useState([]);
  const [JSONProgress, setJSONProgress] = useState([]);
  const [testJSONProgress, setTestJSONProgress] = useState([]);

  const _uploadProgress = useRef([]);
  const _versionCloneProgress = useRef([]);
  const _JSONProgress = useRef([]);
  const _testJSONProgress = useRef([]);

  const handleFormDialogClose = (_loading) => () => {
    if (!_loading) {
      setFormDialog({
        open: false,
      });
    }
  };

  const versions = props?.registryVersions?.versions
    ? Object.keys(props.registryVersions.versions)
    : null;
  const initialDialog = {
    title: t("deleteVersion") + "?",
    text: t("deleteConfirmation") + " " + t("deleteVersionConfirmation"),
    visible: false,
    value: null,
    fn: handleDelete,
  };

  const [dialog, setDialog] = useState(initialDialog);

  const handleChange = (name, type, event, numeric) => {
    let _value;

    if (type === "datePicker") {
      _value = event;
    } else {
      _value = onTextChange(event.target.value, numeric);
    }

    setInputs({
      ...inputs,
      [name]: {
        ...inputs[name],
        value: _value,
        error: "",
      },
    });
  };

  const requestProgress = () => {
    request("packages/upload/progress").then((res) => {
      if (res.status === 200) {
        if (Array.isArray(res.data) && res.data.length > 0) {
          // data = array with
          // progress = progress percentage
          // processName = process name
          // step = process step
          // statusMessage = status message
          // error = error message
          // registryVersion = registryVersion

          // new registryVersion
          let XMLUpload;
          // product list
          let XLSXUpload;
          let uploadingData = [];
          // draft creation
          let cloningVersions = [];
          let creatingJSONs = [];
          let creatingTestJSONs = [];

          res.data.forEach((x) => {
            switch (x.processName) {
              case "XMLUpload":
                XMLUpload = x;
                break;
              case "XLSXUpload":
                XLSXUpload = x;
                break;
              case "VersionClone":
                cloningVersions.push(x);
                break;
              case "JSONCreation":
                creatingJSONs.push(x);
                break;
              case "JSONCreationExample":
                creatingTestJSONs.push(x);
                break;
              default:
                break;
            }
          });

          if (XMLUpload && XLSXUpload) {
            uploadingData = [XMLUpload, XLSXUpload];
            setUploadProgress(uploadingData);
            if (XMLUpload.error) {
              if (!XLSXUpload.error) {
                setLoading("xlsx");
              }
            } else if (XLSXUpload.error) {
              setLoading("xml");
            } else {
              setLoading("xml&xlsx");
            }
          } else if (XMLUpload) {
            uploadingData = [XMLUpload];
            setUploadProgress([XMLUpload]);
            setLoading("xml");
          } else if (XLSXUpload) {
            uploadingData = [XLSXUpload];
            setUploadProgress([XLSXUpload]);
            setLoading("xlsx");
          }

          if (
            _uploadProgress.current.length !== uploadingData.length ||
            _versionCloneProgress.current.length !== cloningVersions.length ||
            _JSONProgress.current.length !== creatingJSONs.length ||
            _testJSONProgress.current.length !== creatingTestJSONs.length
          ) {
            getVersions(
              props.SET_REGISTRY_VERSIONS,
              undefined,
              props.SET_VERSION,
              t
            );
          }

          _uploadProgress.current = uploadingData;
          _versionCloneProgress.current = cloningVersions;
          _JSONProgress.current = creatingJSONs;
          _testJSONProgress.current = creatingTestJSONs;

          setVersionCloneProgress(cloningVersions);
          setJSONProgress(creatingJSONs);
          setTestJSONProgress(creatingTestJSONs);
        } else {
          setLoading("");

          if (
            _uploadProgress.current.length !== 0 ||
            _versionCloneProgress.current.length !== 0 ||
            _JSONProgress.current.length !== 0 ||
            _testJSONProgress.current.length !== 0
          ) {
            getVersions(
              props.SET_REGISTRY_VERSIONS,
              undefined,
              props.SET_VERSION,
              t
            );
          }

          _uploadProgress.current = [];
          _versionCloneProgress.current = [];
          _JSONProgress.current = [];
          _testJSONProgress.current = [];

          setUploadProgress([]);
          setVersionCloneProgress([]);
          setJSONProgress([]);
          setTestJSONProgress([]);
        }
      }
    });
  };

  useEffect(() => {
    requestProgress();
    let id = setInterval(requestProgress, 5000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadScreen === "xlsx") {
      requestWholeSalers(props.SET_COMMON_PROP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadScreen]);

  function handleDelete(value) {
    request("packages/versions/" + value, "delete").then((res) => {
      if (res.status === 200 || res.status === 204) {
        toast.success(t("version") + ` ${value} ` + t("deletionSuccess"));
        // remove persisted data relating to the version
        const localStorageKeys = Object.keys(localStorage);
        localStorageKeys.forEach((x) => {
          // key may be prefix/root/version
          // or root/version
          const splitKey = x.split("/");
          const storageKeyVersion = splitKey.pop();
          if (storageKeyVersion === value) {
            localStorage.removeItem(x);
          }
        });
        getVersions(
          props.SET_REGISTRY_VERSIONS,
          undefined,
          props.SET_VERSION,
          t
        );
      }
    });
  }

  function handlePublish(value) {
    request("packages/versions/publish", "put", { version: value }).then(
      (res) => {
        if (res.status === 200)
          props.SET_PUBLISHED_VERSION({ registryVersion: value });
      }
    );
  }

  useEffect(() => {
    getVersions(props.SET_REGISTRY_VERSIONS, undefined, props.SET_VERSION, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshing === "versions") {
      getVersions(
        props.SET_REGISTRY_VERSIONS,
        setRefreshing,
        props.SET_VERSION,
        t
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  function setDialogWithParams(params) {
    setDialog({
      ...dialog,
      ...params,
    });
  }

  function setDialogVisible(visible) {
    setDialog({
      ...dialog,
      visible,
    });
  }

  const validate = () => {
    let err = false;
    let _inputs = inputs;
    Object.entries(inputs).forEach(([key, val]) => {
      if (!val.optional && !val.value) {
        err = true;
        _inputs = update(_inputs, {
          [key]: { error: { $set: t("requiredErr") } },
        });
      }
    });

    if (err) {
      setInputs(_inputs);
    }
    return err;
  };

  function uploadFiles(body) {
    return fileUpload(
      uploadScreen === "xml" ? "/packages/xml/upload" : "packages/xlsx/upload",
      body,
      () => {
        setLoading("xml");
      },
      (err) => {
        toast(t("fileUploadErr"));
      }
    );
  }

  function appendInputsToBody(bodyFormData) {
    Object.entries(inputs).forEach(([key, val]) => {
      if (inputs[key].type === "datePicker") {
        bodyFormData.append(
          key,
          moment(inputs[key].value).format(inputs[key].format || "DD.MM.YYYY")
        );
      } else {
        bodyFormData.append(key, inputs[key].value);
      }
    });
    return bodyFormData;
  }

  const createUploadBody = (uploadScreen) => (files) => {
    if (!validate()) {
      setLoading(uploadScreen);
      let bodyFormData = new FormData();

      files.forEach((file) => {
        bodyFormData.append("file", file);
      });

      uploadFiles(appendInputsToBody(bodyFormData));
    }
  };

  const handleScreenChange = (screen) => {
    if (screen === "xlsx") {
      setInputs(initialInputs);
    } else if (screen === "xml") {
      setInputs(initialVersionInputs);
    }
    setUploadScreen(screen);
  };

  const renderButtons = (extraButtons) => (
    <Stack direction="row" spacing={2} sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Button
        color="basic"
        variant="contained"
        disabled={loading?.includes("xlsx") ? true : false}
        onClick={() => handleScreenChange("xlsx")}
      >
        {t("uploadNewExcel")}
      </Button>
      <Button
        color="basic"
        variant="contained"
        disabled={loading?.includes("xml") ? true : false}
        onClick={() => handleScreenChange("xml")}
      >
        {t("uploadNewVersion")}
      </Button>
      {extraButtons}
    </Stack>
  );

  if (uploadScreen) {
    const _loading = loading?.includes(uploadScreen);
    const _uploadProgress = uploadProgress.find((x) =>
      uploadScreen === "xlsx"
        ? x.processName === "XLSXUpload"
        : x.processName === "XMLUpload"
    );
    return (
      <Box sx={{ width: props.width, height: props.height, overflow: "auto" }}>
        <Container maxWidth="lg">
          <Stack p={4} spacing={2}>
            <Typography variant="h5">
              {t(
                uploadScreen === "xlsx" ? "uploadNewExcel" : "uploadNewVersion"
              )}
            </Typography>
            <ProgressIndicator
              title={_uploadProgress?.statusMessage}
              errorMessage={_uploadProgress?.error}
              progress={_uploadProgress?.progress}
            />
            {!_loading &&
              uploadScreen &&
              Object.keys(inputs).map((inputKey) => {
                const _key = `Input${inputKey}`;
                return (
                  <div
                    key={_key}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <FormComponent
                      input={inputs[inputKey]}
                      prop={inputKey}
                      handleChange={handleChange}
                      parentProps={props}
                    />
                  </div>
                );
              })}
            {!_loading && (
              <StyledDropzone
                maxFiles={1}
                acceptedFiles={
                  uploadScreen === "xml"
                    ? ["application/zip", "application/x-zip-compressed"]
                    : [
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                      ]
                }
                uploadFiles={createUploadBody(uploadScreen)}
              />
            )}
            <Button
              color="basic"
              variant="contained"
              disabled={_loading}
              onClick={() => setUploadScreen(false)}
            >
              {t("back")}
            </Button>
          </Stack>
        </Container>
      </Box>
    );
  } else if (versions) {
    return (
      <>
        <Container maxWidth="lg">
          {uploadProgress.map((x, i) => (
            <ProgressIndicator
              title={x.statusMessage}
              errorMessage={x.error}
              progress={x.progress}
            />
          ))}

          {renderButtons([
            <LoadingButton
              key="updateBtn"
              loading={refreshing === "versions"}
              loadingPosition="start"
              startIcon={<MdRefresh />}
              disabled={refreshing === "versions"}
              onClick={() => setRefreshing("versions")}
              variant="contained"
              color="basic"
            >
              {t("update")}
            </LoadingButton>,
          ])}

          <VersionInfoCard
            t={t}
            title={t("currentVersionTitle") + ": "}
            registryVersionId={props?.registryVersions?.publishedVersion}
            registryVersionObject={
              props?.registryVersions?.versions?.[
                props?.registryVersions?.publishedVersion
              ]
            }
            index={-1}
            setDialog={setDialogWithParams}
            SET_VERSION={props.SET_VERSION}
            publishedVersion={props?.registryVersions?.publishedVersion}
            selectedVersion={props.selectedVersion}
            versionAsSelect={true}
            versions={versions.filter((x) => !x.includes("_"))}
            handlePublish={handlePublish}
            creatingDraftOrCopy={versionCloneProgress.find(
              (progress) =>
                Number(progress.registryVersion) ===
                Number(props?.registryVersions?.publishedVersion)
            )}
            disableButtons
          />

          <Box
            sx={{
              height:
                props.height - 29 - 69 - 239 - uploadProgress.length * 146,
              overflow: "auto",
              marginTop: 2,
              paddingBottom: 2,
            }}
          >
            <Stack spacing={2}>
              {versions.map((x) => {
                if (x.includes("_")) return null;
                const registryVersionId = x;
                const draftRegistryVersionId = x + "_draft";
                return (
                  <Stack
                    key={"VersionInfoCards" + x}
                    direction="row"
                    spacing={2}
                  >
                    <VersionInfoCard
                      key={"VersionInfoCard" + x}
                      t={t}
                      title={t("version") + ": "}
                      registryVersionId={registryVersionId}
                      registryVersionObject={
                        props.registryVersions.versions[registryVersionId]
                      }
                      setDialog={setDialogWithParams}
                      SET_VERSION={props.SET_VERSION}
                      publishedVersion={
                        props.registryVersions?.publishedVersion
                      }
                      selectedVersion={props.selectedVersion}
                      creatingDraftOrCopy={
                        !!versionCloneProgress.find(
                          (progress) =>
                            Number(progress.registryVersion) === Number(x)
                        )
                      }
                      creatingJSON={
                        !!JSONProgress.find(
                          (progress) =>
                            Number(progress.registryVersion) === Number(x)
                        )
                      }
                      creatingTestJSON={
                        !!testJSONProgress.find(
                          (progress) =>
                            Number(progress.registryVersion) === Number(x)
                        )
                      }
                      _versionCloneProgress={_versionCloneProgress}
                      _JSONProgress={_JSONProgress}
                      _testJSONProgress={_testJSONProgress}
                      setVersionCloneProgress={setVersionCloneProgress}
                      setJSONProgress={setJSONProgress}
                      setTestJSONProgress={setTestJSONProgress}
                      handleDelete={handleDelete}
                      setFormDialog={setFormDialog}
                      SET_REGISTRY_VERSION={props.SET_REGISTRY_VERSION}
                    />
                    <VersionInfoCard
                      key={"DraftVersionInfoCard" + x}
                      t={t}
                      title={t("version") + ": "}
                      isDraft={true}
                      hasDraft={
                        !!versions.find((x) => x === draftRegistryVersionId)
                      }
                      registryVersionId={draftRegistryVersionId}
                      registryVersionObject={
                        props.registryVersions.versions[draftRegistryVersionId]
                      }
                      setDialog={setDialogWithParams}
                      SET_VERSION={props.SET_VERSION}
                      publishedVersion={
                        props.registryVersions?.publishedVersion
                      }
                      selectedVersion={props.selectedVersion}
                      creatingDraftOrCopy={
                        !!versionCloneProgress.find(
                          (progress) =>
                            progress.registryVersion === draftRegistryVersionId
                        )
                      }
                      creatingJSON={
                        !!JSONProgress.find(
                          (progress) =>
                            progress.registryVersion === draftRegistryVersionId
                        )
                      }
                      creatingTestJSON={
                        !!testJSONProgress.find(
                          (progress) =>
                            progress.registryVersion === draftRegistryVersionId
                        )
                      }
                      _versionCloneProgress={_versionCloneProgress}
                      _JSONProgress={_JSONProgress}
                      _testJSONProgress={_testJSONProgress}
                      setVersionCloneProgress={setVersionCloneProgress}
                      setJSONProgress={setJSONProgress}
                      setTestJSONProgress={setTestJSONProgress}
                      handleDelete={handleDelete}
                      setFormDialog={setFormDialog}
                      SET_REGISTRY_VERSION={props.SET_REGISTRY_VERSION}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Box>
          <ConfirmDialog
            title={dialog.title}
            open={dialog.visible}
            setOpen={setDialogVisible}
            value={dialog.value}
            onConfirm={dialog.fn}
          >
            {dialog.text + (dialog.dontDisplayValue ? "" : " " + dialog.value)}
          </ConfirmDialog>
          <Modal
            open={formDialog.open}
            onClose={handleFormDialogClose(formDialog.loading)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: 700,
              }}
            >
              <Form
                {...formDialog}
                saving={formDialog.loading}
                onClose={handleFormDialogClose(formDialog.loading)}
                t={t}
                onSave={formDialog.handleSubmit}
                editable
                disablePaths
                disableJSON
                disableEditButtons
              />
            </Box>
          </Modal>
        </Container>
      </>
    );
  } else {
    return (
      <Container maxWidth="lg">
        {renderButtons([
          <LoadingButton
            key="updateBtn"
            loading={refreshing === "versions"}
            loadingPosition="start"
            startIcon={<MdRefresh />}
            disabled={refreshing === "versions"}
            onClick={() => setRefreshing("versions")}
            variant="contained"
            color="basic"
          >
            {t("update")}
          </LoadingButton>,
        ])}
        <Box
          sx={{
            height: props.height - 69,
            overflow: "auto",
            display: "flex",
            paddingLeft: 20,
            fontSize: 12,
          }}
        >
          <Typography variant="h6">{t("noVersions")}</Typography>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedVersion: localStorage.getItem("registryVersion"),
    registryVersions: state.versions.registryVersions,
    wholeSalers: state.common.wholeSalers,
  };
};

export default connect(mapStateToProps, {
  SET_VERSION,
  SET_REGISTRY_VERSIONS,
  SET_REGISTRY_VERSION,
  SET_PUBLISHED_VERSION,
  SET_COMMON_PROP,
})(VersionList);
